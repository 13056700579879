import type Swiper from 'swiper';
import type { SwiperOptions } from 'swiper/types';

import whenDomReady from 'when-dom-ready';

interface SlideMapEntry {
    slide: HTMLElement;
    slideIndex: number;
}

class SwiperExtension {
    protected lastActiveNavElement: HTMLElement | null = null;
    protected slideMap: Map<string, SlideMapEntry> | null = null;
    protected navMap: Map<string, HTMLElement> = new Map();
    protected swiper: Swiper | null = null;

    constructor(wrapperElement: HTMLElement) {
        this.parseNavItems(wrapperElement);
        const slideshowContainer = wrapperElement.querySelector<HTMLElement>(
            '[data-gh-slider="main"]'
        );

        if (slideshowContainer && window.Swiper) {
            const scrollbarContainer =
                wrapperElement.querySelector<HTMLElement>(
                    '.swiper-drag-wrapper'
                );

            const swiperOptions: SwiperOptions = {
                speed: 600,
                loop: false,
                autoHeight: false,
                followFinger: true,
                freeMode: false,
                slideToClickedSlide: false,
                slidesPerView: 'auto',
                spaceBetween: 24,
                rewind: false,
                mousewheel: {
                    forceToAxis: true,
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
                slideActiveClass: 'is-active',
                createElements: true,
                on: {
                    init: this.onSlideChanged,
                    activeIndexChange: this.onSlideChanged,
                },
            };

            if (scrollbarContainer) {
                swiperOptions.scrollbar = {
                    el: scrollbarContainer,
                    draggable: true,
                    dragClass: 'swiper-drag',
                    snapOnRelease: true,
                };
            }

            this.swiper = new window.Swiper(slideshowContainer, swiperOptions);
        }
    }

    protected setActiveNavElement(newActiveNavElement: HTMLElement): void {
        if (newActiveNavElement !== this.lastActiveNavElement) {
            if (this.lastActiveNavElement) {
                this.lastActiveNavElement.classList.remove('active');
            }

            newActiveNavElement.classList.add('active');
            this.lastActiveNavElement = newActiveNavElement;
            this.maybeScrollNavToVisible(newActiveNavElement);
        }
    }

    protected maybeScrollNavToVisible(navElement: HTMLElement) {
        const container = navElement.parentElement as HTMLElement;

        const containerWidth = container.offsetWidth;
        const containerScroll = container.scrollLeft;
        const elementWidth = navElement.offsetWidth;
        const elementOffset = navElement.offsetLeft;

        const effectiveVisibleEnd = containerWidth + containerScroll;
        const effectiveEndPosition = elementOffset + elementWidth;

        const isEndVisible = effectiveVisibleEnd >= effectiveEndPosition;
        const isStartVisible = containerScroll <= elementOffset;
        const isFullyVisible = isEndVisible && isStartVisible;

        if (!isFullyVisible) {
            const center = containerWidth / 2;
            const halfElement = elementWidth / 2;
            // const center = containerWidth / 2;
            if (elementOffset < center - halfElement) {
                container.scrollLeft = elementOffset;
            } else {
                container.scrollLeft = elementOffset - (center - halfElement);
            }
        }
    }

    protected onSlideChanged = (swiperPassed: Swiper | any): void => {
        const s = this.swiper || (swiperPassed as Swiper);

        if (!this.slideMap) {
            this.parseSlides(s);
        }

        const index = s.realIndex;
        const slide = s.slides[index];
        const slideTag = this.getTagFromElement(slide);

        if (slideTag && this.navMap.has(slideTag)) {
            const navElement = this.navMap.get(slideTag) as HTMLElement;
            this.setActiveNavElement(navElement);
        }
    };

    protected parseSlides(swiper: Swiper): void {
        this.slideMap = new Map();
        swiper.slides.forEach((slide, slideIndex) => {
            const tag = this.getTagFromElement(slide);

            if (tag && !this.slideMap?.has(tag)) {
                this.slideMap!.set(tag, { slide, slideIndex });
            }
        });
    }

    protected parseNavItems(wrapper: HTMLElement): void {
        const nav = wrapper.querySelector<HTMLElement>(
            '[data-gh-slider="nav"]'
        );
        const navItems =
            (nav && (Array.from(nav.children) as HTMLElement[])) || [];

        navItems.forEach((e) => {
            const tag = this.getTagFromElement(e);

            if (tag) {
                this.navMap.set(tag, e);

                e.addEventListener('click', () => {
                    if (this.slideMap?.has(tag)) {
                        const { slideIndex } = this.slideMap.get(
                            tag
                        ) as SlideMapEntry;
                        if (slideIndex > -1) {
                            this.swiper?.slideTo(slideIndex);
                        }
                    }
                });
            }
        });
    }

    protected getTagFromElement(element: HTMLElement): string | null {
        let tag = element.dataset.slideTag || null;

        if (!tag) {
            tag =
                element.querySelector<HTMLElement>('[data-slide-tag="true"]')
                    ?.innerText || null;
        }

        return tag;
    }
}

whenDomReady().then(() => {
    const sliders = document.querySelectorAll<HTMLElement>(
        '[data-gh-slider="root"]'
    );
    window.__debug = [];

    sliders.forEach((e) => {
        const ext = new SwiperExtension(e);
        window.__debug.push(ext);
    });
});
